<template>
  <div class="person-view-page container" v-if="person">
    <h1>Edit person</h1>
    <hr />

    <form>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control" id="name" v-model="name" :disabled="!currentAccount.hasPermission('persons.edit')" />
      </div>
      <div class="mb-3">
        <label for="phone" class="form-label">Phone</label>
        <input type="text" class="form-control" id="phone" v-model="phone" :disabled="!currentAccount.hasPermission('persons.edit')" />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email</label>
        <input type="text" class="form-control" id="email" v-model="email" :disabled="!currentAccount.hasPermission('persons.edit')" />
      </div>
      <div class="mb-3">
        <label for="companyName" class="form-label">Company name</label>
        <input
          type="text"
          class="form-control"
          id="companyName"
          v-model="companyName"
          :disabled="!currentAccount.hasPermission('persons.edit')"
        />
      </div>
      <div class="mb-3">
        <label for="status" class="form-label">Resident status</label>
        <select class="form-select" id="status" v-model="status" :disabled="!currentAccount.hasPermission('persons.edit')">
          <option value="default" disabled>Select status</option>
          <option v-for="personStatus in personStatusList" :value="personStatus">{{ getStatusTranslate(personStatus) }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label class="form-label">Languages</label>
        <div class="row">
          <template v-for="(lang, index) in langs">
            <div class="card col-md-2 mb-3 ms-3 flex flex-row">
              <div class="card-body">
                <p class="card-text">{{ lang }}</p>
              </div>
              <span class="close"><i class="fa fa-times" title="Remove" @click="() => removeLang(index)"></i></span>
            </div>
          </template>
          <div class="new-lang col-md-2">
            <SelectWithSearch :clearOnSelect="true" :items="availableLangs" @search="onLangSearch" />
          </div>
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3 form-check">
          <label for="canCrossBorder" class="form-check-label">Can't cross border</label>
          <input
            type="checkbox"
            class="form-check-input"
            id="canCrossBorder"
            v-model="cantCrossBorder"
            :disabled="!currentAccount.hasPermission('persons.edit')"
          />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="canCrossCanadianBorder" class="form-check-label">Can cross canadian border</label>
          <input type="checkbox" class="form-check-input" id="canCrossCanadianBorder" v-model="canCrossCanadianBorder" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="canCrossMexicanBorder" class="form-check-label">Can cross mexican border</label>
          <input type="checkbox" class="form-check-input" id="canCrossMexicanBorder" v-model="canCrossMexicanBorder" />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasTsaCard" class="form-check-label">TSA card</label>
          <input
            type="checkbox"
            class="form-check-input"
            id="hasTsaCard"
            v-model="hasTsaCard"
            :disabled="!currentAccount.hasPermission('persons.edit')"
          />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasTwicCard" class="form-check-label">TWIC card</label>
          <input
            type="checkbox"
            class="form-check-input"
            id="hasTwicCard"
            v-model="hasTwicCard"
            :disabled="!currentAccount.hasPermission('persons.edit')"
          />
        </div>
        <div class="mb-3 me-3 form-check">
          <label for="hasHazmatCert" class="form-check-label">HAZMAT certificate</label>
          <input
            type="checkbox"
            class="form-check-input"
            id="hasHazmatCert"
            v-model="hasHazmatCert"
            :disabled="!currentAccount.hasPermission('persons.edit')"
          />
        </div>
      </div>

      <div class="mb-3 col-md-12 home-location">
        <label for="homeLocation" class="form-label">Home location</label>
        <div class="d-flex flex-wrap align-items-center">
          <SelectWithSearch
            :inputValue="homeLocation?.postalCode"
            :items="homeLocations"
            @search="onHomeLocationSearch"
            @clear="onHomeLocationClear"
          />
          <span class="ms-3">{{ homeLocation?.formattedAddress }}</span>
        </div>
      </div>

      <div class="col-md-12 d-flex flex-wrap">
        <div class="mb-3 me-3 col-md-4">
          <label class="form-label">Who hired?</label>
          <div class="home-location">
            <SelectWithSearch
              :inputValue="hiredByAccount?.login"
              :items="hiredByAccounts"
              @search="onHiredBySearch"
              @clear="onHiredByClear"
            />
          </div>
        </div>

        <div class="mb-3 me-3 col-md-4">
          <label for="hiredAt" class="form-label">When hired?</label>
          <input type="date" class="form-control" id="hiredAt" v-model="hiredAt" />
        </div>
      </div>

      <button type="button" class="btn btn-primary" :disabled="!currentAccount.hasPermission('persons.edit')" @click="save">Save</button>
      <button type="button" class="btn btn-danger ms-2" :disabled="!currentAccount.hasPermission('persons.remove')" @click="remove">
        Delete
      </button>

      <span class="ms-3 text-muted">
        <template v-if="person.updatedAt">
          Updated by <b>{{ person.updatedByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(person.updatedAt)) }}</b>
        </template>
        <template v-else>
          Created by <b class="font-weight-bold">{{ person.createdByAccount?.login ?? 'Unknown' }}</b> at
          <b>{{ dateTimeFormatter.format(new Date(person.createdAt)) }}</b>
        </template>
      </span>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTimeFormatter } from '@/utils';
import SelectWithSearch from '@/components/SelectWithSearch';
import { PersonStatus, PersonLang } from 'fifty-seven-llc-common';
import { personService } from '@/modules/person/person.service';
import { findPerson, updatePerson, deletePerson, getLocationList, getAccountList } from '@/api';

export default {
  name: 'PersonEditPage',
  components: { SelectWithSearch },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
    personStatusList() {
      return { ...PersonStatus };
    },
    personLangList() {
      return { ...PersonLang };
    },

    availableLangs() {
      return [...Object.keys(PersonLang)]
        .filter(i => !this.langs.find(j => j === i))
        .map(i => ({
          title: i,
          onClick: () => this.addLang(i),
        }));
    },
  },
  data: () => ({
    name: '',
    phone: '',
    email: '',
    status: 'default',
    langs: [],
    cantCrossBorder: false,
    catCrossCanadianBorder: false,
    catCrossMexicanBorder: false,
    hasTsaCard: false,
    hasTwicCard: false,
    hasHazmatCert: false,

    homeLocations: [],
    homeLocation: null,

    hiredAt: null,
    hiredByAccounts: [],
    hiredByAccount: null,

    companyName: '',

    person: null,

    dateTimeFormatter,
  }),
  methods: {
    getStatusTranslate(status) {
      return personService.getStatusTranslate(status);
    },

    onLangSearch() {},

    async addLang(lang) {
      this.langs.push(lang);
    },

    removeLang(index) {
      this.langs.splice(index, 1);
    },

    async onHomeLocationSearch(search) {
      const response = await getLocationList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.homeLocations = response.data.locations.map(i => ({
        title: i.formattedAddress,
        onClick: () => this.setHomeLocation(i),
      }));
    },

    onHomeLocationClear() {
      this.setHomeLocation(null);
    },

    setHomeLocation(location) {
      this.homeLocation = location;
    },

    async onHiredBySearch(search) {
      const response = await getAccountList(100, 0, search);

      if (response?.errors) {
        this.$toast.error(response.errors[0].message);
        return;
      }

      this.hiredByAccounts = response.data.accounts.map(i => ({
        title: i.login,
        onClick: () => this.setHiredBy(i),
      }));
    },

    onHiredByClear() {
      this.setHiredBy(null);
    },

    setHiredBy(account) {
      this.hiredByAccount = account;
    },

    async update(withReset = true) {
      try {
        if (withReset) {
          this.person = null;
        }

        const id = this.$route.params.id;
        if (!id) {
          return;
        }

        const response = await findPerson(id);
        if (response?.errors) {
          throw new Error(response.errors[0].message);
        }

        this.person = response?.data?.person;
        if (!this.person) {
          throw new Error('Person not found');
        }

        this.name = this.person.name;
        this.phone = this.person.phone;
        this.email = this.person.email;
        this.status = this.person.status;
        this.lang = this.person.lang;
        this.cantCrossBorder = !this.person.canCrossBorder;
        this.canCrossCanadianBorder = this.person.canCrossCanadianBorder;
        this.canCrossMexicanBorder = this.person.canCrossMexicanBorder;
        this.hasTsaCard = this.person.hasTsaCard;
        this.hasTwicCard = this.person.hasTwicCard;
        this.hasHazmatCert = this.person.hasHazmatCert;
        this.homeLocation = this.person.homeLocation;
        this.hiredAt = this.person.hiredAt;
        this.hiredByAccount = this.person.hiredByAccount;
        this.companyName = this.person.companyName;

        this.langs = JSON.parse(JSON.stringify(this.person.langs));
      } catch (error) {
        console.error(error);
        this.$toast.error(error.message);
      }
    },

    async save() {
      try {
        const data = {};

        if (this.name?.trim() !== this.person.name) {
          data.name = this.name?.trim();
        }

        if (this.phone?.trim() !== this.person.phone) {
          data.phone = this.phone?.trim();
        }

        if (this.email?.trim() !== this.person.email) {
          data.email = this.email;
        }

        if (this.companyName?.trim() !== this.person.companyName) {
          data.companyName = this.companyName?.trim();
        }

        if (this.status && this.status !== this.person.status) {
          data.status = this.status;
        }

        if (this.cantCrossBorder === this.person.canCrossBorder) {
          data.canCrossBorder = !this.cantCrossBorder;
        }

        if (this.catCrossCanadianBorder !== this.person.canCrossCanadianBorder) {
          data.canCrossCanadianBorder = this.catCrossCanadianBorder;
        }

        if (this.catCrossMexicanBorder !== this.person.canCrossMexicanBorder) {
          data.canCrossMexicanBorder = !this.catCrossMexicanBorder;
        }

        if (this.hasTsaCard !== this.person.hasTsaCard) {
          data.hasTsaCard = this.hasTsaCard;
        }

        if (this.hasTwicCard !== this.person.hasTwicCard) {
          data.hasTwicCard = this.hasTwicCard;
        }

        if (this.hasHazmatCert !== this.person.hasHazmatCert) {
          data.hasHazmatCert = this.hasHazmatCert;
        }

        const homeLocationId = this.homeLocation?.id ?? null;
        if (homeLocationId !== this.person.homeLocationId) {
          data.homeLocationId = homeLocationId;
        }

        if (this.hiredAt !== this.person.hiredAt) {
          data.hiredAt = this.hiredAt || null;
        }

        const hiredById = this.hiredByAccount?.id ?? null;
        if (hiredById !== this.person.hiredBy) {
          data.hiredBy = hiredById;
        }

        if (!this.langs.length) {
          return this.$toast.error('Select at least one language.');
        }

        {
          const langsCopy = [...this.langs];
          const existingLangsCopy = [...this.person.langs];
          for (let i = 0; i < langsCopy.length; i++) {
            const lang = langsCopy[i];
            const existingLangIndex = existingLangsCopy.findIndex(j => j === lang);
            if (existingLangIndex === -1) {
              break;
            }

            langsCopy.splice(i--, 1);
            existingLangsCopy.splice(existingLangIndex, 1);
          }

          if (langsCopy.length || existingLangsCopy.length) {
            data.langs = this.langs;
          }
        }

        const response = await updatePerson(this.$route.params.id, data);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Person was updated.', { duration: 2000 });
        setTimeout(() => this.$router.push({ name: 'persons' }), 2000);
        this.update(false);
      } catch (err) {
        console.error(err);
        this.$toast.error(err.message);
      }
    },

    async remove() {
      try {
        const response = await deletePerson(this.$route.params.id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$router.push({ name: 'persons' });
        this.$toast.success('Person was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },
  },
  activated() {
    this.update();
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.update();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.person-view-page {
  padding: 2em 0;
}

.card {
  color: #212529;
}
</style>
